
import _ from 'lodash';
import { Entitlement } from '../api/src/models/Entitlement';
import uiMixin from '../mixins/ui';

export default {
  name: 'UserSubscriptions',
  mixins: [
    uiMixin,
  ],
  computed: {
    hasEntitlement() {
      return this.$store.getters['userSettings/hasEntitlement'](Entitlement.EntitlementName.enum.Basic);
    },
    entitlementStatus() {
      return this.$store.getters['userSettings/getEntitlementStatus'](Entitlement.EntitlementName.enum.Basic);
    },
    entitlementStatusHeaderDisplay() {
      return Entitlement.EntitlementStatusHeaderDisplayMap[this.entitlementStatus];
    },
    entitlementStatusDisplay() {
      return Entitlement.EntitlementStatusDisplayMap[this.entitlementStatus];
    },
    entitlementSetting() {
      return this.$store.getters['userSettings/getSettingForEntitlement'](Entitlement.EntitlementName.enum.Basic);
    },
    entitlementSettingExpiresAtDisplay() {
      return this.entitlementSetting
        ? this.$dayjs(this.entitlementSetting.expiresAt).format('YYYY-MM-DD hh:mm a')
        : '';
    },
    entitlementStatusExpiresDisplay() {
      return this.entitlementSettingExpiresAtDisplay
        ? `${Entitlement.EntitlementStatusExpiresDisplayMap[this.entitlementStatus]}: ${this.entitlementSettingExpiresAtDisplay}`
        : '';
    },
    isEntitlementLifetime() {
      return this.entitlementStatus === Entitlement.EntitlementStatus.enum.Lifetime;
    },
    isEntitlementExpiringGrace() {
      return this.entitlementStatus === Entitlement.EntitlementStatus.enum.ExpiredGrace;
    },
  },
  methods: {
    tsToDateStr(d) {
      return this.$dayjs(d).format('YYYY-MM-DD hh:mm a');
    },
    async setTempEntitlement() {
      if (this.isDebugger) {
        await this.$store.dispatch('userSettings/edit', {
          entitlements: [{
            name: Entitlement.EntitlementName.enum.Basic,
            expiresAt: this.$dayjs().add(1, 'day'),
            isLifetime: false,
          }],
        });

        this.$store.dispatch('updateNowValue');

        this.uiNotify('Temporary subscription activated!', {
          severity: 'success',
          icon: ['fas', 'face-sunglasses'],
          logMessage: 'temporary subscription activated',
        });
      }
    },
    async setGraceEntitlement() {
      if (this.isDebugger) {
        await this.$store.dispatch('userSettings/edit', {
          entitlements: [{
            name: Entitlement.EntitlementName.enum.Basic,
            expiresAt: this.$dayjs().subtract(1, 'day').add(1, 'hour'),
            isLifetime: false,
          }],
        });

        this.$store.dispatch('updateNowValue');

        this.uiNotify('Temporary subscription activated!', {
          severity: 'success',
          icon: ['fas', 'face-sunglasses'],
          logMessage: 'temporary subscription activated',
        });
      }
    },
    async setLifetimeEntitlement() {
      if (this.isDebugger) {
        await this.$store.dispatch('userSettings/setEntitlementIsLifetime');

        this.$store.dispatch('updateNowValue');

        this.uiNotify('Lifetime subscription activated!', {
          severity: 'success',
          icon: ['fas', 'face-sunglasses'],
          logMessage: 'lifetime subscription activated',
        });
      }
    },
    async deleteEntitlement() {
      // This mimics what the backend Purchases function does on deactivation
      if (this.isDebugger) {
        let { entitlements } = this.$store.state.userSettings;

        if (Array.isArray(entitlements)) {
          entitlements = _.cloneDeep(entitlements);
          const existing = entitlements.findIndex(e => e.name === Entitlement.EntitlementName.enum.Basic);

          if (existing >= 0) {
            entitlements.splice(existing, 1);
          }

          await this.$store.dispatch('userSettings/edit', {
            entitlements,
          });
        }

        this.$store.dispatch('updateNowValue');

        this.uiNotify('Subscription deactivated.', {
          severity: 'warning',
          icon: ['fas', 'triangle-exclamation'],
          logMessage: 'Subscription deactivated',
        });
      }
    },
  },
};
